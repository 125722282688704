@font-face {
    font-family: "Metamorphous";
    src: local("Metamorphous"), url("./fonts/metamorphous.ttf");
}

@font-face {
    font-family: "Black Castle MF";
    src: local("Black Castle MF"), url("./fonts/black_castle_mf.ttf");
}

body {
    background: transparent;
    color: #111122;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: 'Metamorphous';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

hr {
    width: 100%;
}

h1 {
    font-size: 24pt;
}

/* h2, h3, h4, h5, h6 {
} */

a {
    color: #DD66DD;
    text-decoration: none;
}
a:hover {
    color: #4ADE80;
}

input, textarea {
    font-family: 'Metamorphous';
    font-size: 16px;
    padding: 4px 16px;
    border-width: 1px;
    border-radius: 4px;
}

textarea {
    width: calc(100% - 2em);
    min-height: 8em;
    padding: 1em;
}

button {
    background: #DD00DD77;
    border-color: #F8FAFC;
    border-width: 1px;
    border-radius: 0.25em;
    border-style: outset;
    padding: 4px 16px;
    flex: 1;
    font-family: 'Metamorphous';
    font-size: 16px;
    color: #F8FAFC;
    text-shadow: 2px 2px #00000022;
}
button:hover {
    background: #4ADE8077;
}

.img-small {
    max-width: 200px;
}

.video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.video-wrapper iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/** Spread Shop **/
.customPageBackground {
    background: none !important;
}

.sprd-header, .sprd-header-container {
    background: #99009955 !important;
    color: #64748B !important;
}

.sprd-social-bar__about-link, .sprd-social-bar__label, .sprd__caption-link, .sprd__caption, .customPageBackground,
.sprd-c-s2 {
    color: #64748B !important;
}

#myShop {
    text-align: left;
}